<style scoped>
.b-table>tbody>tr:nth-of-type(odd) {
    --bs-table-accent-bg: red !important;
    color: red !important;
}
</style>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import { required } from "vuelidate/lib/validators";
export default {
    page: {
        title: "",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: { Layout, PageHeader, Multiselect, DatePicker },
    data() {
        return {
            isReportTableBusy: false,
            excelDownloading: false,
            tableData: [],
            title: "Support Tickets",
            items: [
                {
                    text: "View",
                },
                {
                    text: "Support Tickets",
                    active: true,
                },
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100, 500, "All"],
            filter: null,
            submitted: false,
            filterOn: [],
            sortBy: "age",
            sortDesc: false,
            fields: [
                {
                    key: "Action",
                    tdClass: "align-center",
                    sortable: false,
                    thStyle: { backgroundColor: '#f6f6f6' }
                },
                {
                    key: "TicketId",
                    sortable: true,
                    tdClass: "align-center",
                    thStyle: { backgroundColor: '#f6f6f6' }
                },
                {
                    key: "EmployeeName",
                    sortable: false,
                    tdClass: "align-center",
                    thStyle: { backgroundColor: '#f6f6f6' }
                },
                {
                    key: "mobile",
                    sortable: false,
                    tdClass: "align-center",
                    thStyle: { backgroundColor: '#f6f6f6' }
                },
                {
                    key: "Created",
                    sortable: false,
                    tdClass: "align-center",
                    thStyle: { backgroundColor: '#f6f6f6' }
                },
                {
                    key: "Closed",
                    sortable: false,
                    tdClass: "align-center",
                    thStyle: { backgroundColor: '#f6f6f6' }
                },
                {
                    key: "status",
                    sortable: true,
                    tdClass: "align-center",
                    thStyle: { backgroundColor: '#f6f6f6' }
                }
            ],
            time: "",
            timeFilterOptions: [
                "24 Hours",
                "24-72 Hours",
                "72+",
            ],
            dateType: "",
            daterange: [new Date(), new Date()],
            path: "",
            firstActive: "active",
            secondActive: "",
            totalTicket: 0,
            totalOpen: 0,
            totalClosed: 0,
            status: '1',
            replyField: "",
            supportID: "",
            empID:"",
            actionOpen:false
            // totalEmployee: 0,
        };
    },
    validations: {
        replyField: {
            required,
        },
    },
    computed: {
        /**
         * Total no. of records
         */
        rows() {
            return this.tableData.length;
        },
    },
    mounted() {
        // Set the initial number of items
        this.totalRows = this.items.length;
        this.getAllTickets();
        // this.checkUserRights();
    },
    methods: {
        /**
         * Search the table data with search input
         */
        getColor(status) {
            if (status === 'Closed') return "badge badge-pill bg-soft-success font-size-13 bg-soft-success"
            if (status === 'Open') return "badge badge-pill bg-soft-success font-size-13 bg-soft-warning"

            return "badge badge-pill bg-soft-success font-size-13 bg-soft-danger";
        },

        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        getAllTickets() {
            this.isReportTableBusy = true;
            this.axios
                .post(
                    "getSupportTickets", {
                    'corpBranchID': this.$storageData.branchID,
                    'daterange': this.daterange,
                    'status': this.status
                })
                .then((response) => {
                    this.isReportTableBusy = false;
                    this.tableData = response.data.data;
                    this.totalTicket = response.data.totalTicketCount;
                    this.totalOpen = response.data.totalOpenTicketCount;
                    this.totalClosed = response.data.totalClosedTicketCount;
                });
        },

        clearFilter() {
            this.daterange = [new Date(), new Date()];
            this.getAllTickets();
        },

        applyFilter() {
            if (this.daterange.includes(null)) {
                this.daterange = [];
            }
            this.getAllTickets();
        },

        filterDataByRange(range) {
            var now = new Date();
            switch (range) {
                case 1:
                    this.firstActive = "active";
                    this.secondActive = "";
                    this.status = "1";
                    var lastWeek = new Date(new Date(now).setDate(now.getDate() - 7));
                    this.daterange = [lastWeek, now];
                    this.actionOpen=true;
                    this.getAllTickets();
                    break;
                case 2:
                    this.firstActive = "";
                    this.secondActive = "active";
                    this.status = "2";
                    var lastMonth = new Date(new Date(now).setDate(now.getDate() - 30));
                    this.daterange = [lastMonth, now];
                    this.actionOpen=false;
                    this.getAllTickets();
                    break;

            }
        },

        downloadSample() {
            this.excelDownloading = true;
            this.axios
                .post(
                    "exportExcel",
                    {
                        exportData: this.tableData,
                        page: "EmployeeList",
                    },
                    {
                        responseType: "blob",
                    }
                )
                .then((response) => {
                    this.excelDownloading = false;
                    const url = URL.createObjectURL(
                        new Blob([response.data], {
                            type: "application/vnd.ms-excel",
                        })
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "employee-report.xlsx");
                    document.body.appendChild(link);
                    link.click();
                });
        },
       
        giveReply(supportId) {
            this.supportID = supportId;
            this.$root.$emit("bv::show::modal", "modal-lg-reply");
            // this.getFeedbackReviewbyflag();
        },
        
        saveReply(e){
            this.submitted = true;
        // stop here if form is invalid
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        } else {
          this.loading = true;
          let formData = new FormData();
          formData.append('ticketID', this.supportID);
          formData.append('remark', this.replyField);
          formData.append('empID', this.$storageData.id);
          e.preventDefault();
          this.axios.post(
            "updateSupportTickets",formData
          )
          .then(() => {
            this.getAllTickets();
            this.$refs['modalClose'].hide()
          });
        }
    }
    },
    middleware: "authentication",
};
</script>

<template>
    <Layout>
        <PageHeader :items="items" />
        <!--FILTER START------>        <div class="card" style="margin-top: -30px">
            <div class="card-body">
                <div class="inner mb-2 row">
                    <div class="col-md-2" style="width: min-content;" >
                        <label>Filter</label>
                        <div class="check-group btn-group-toggle btn-group bv-no-focus-ring" style="height:auto; width:100%"
                            role="group" aria-label="Date options">
                            <button type="button" class="btn btn-outline-primary" :class="`${firstActive}`"
                                v-on:click="filterDataByRange(1)">Open</button>
                            <button type="button" class="btn btn-outline-primary" :class="`${secondActive}`"
                                v-on:click="filterDataByRange(2)">Closed</button>
                        </div>  
                    </div>

                    <!-- Date range -->
                    <div class="col-md-2" style="width: 20%; ">
                        <label>Date Range </label>
                        <date-picker v-model="daterange" format="DD MMM Y" range append-to-body lang="en">
                        </date-picker>
                    </div>
                    <!-- Date range END-->

                    <!-- Payment Type -->
                    <div class="col-md-2" style="width: 18%;">
                        <label>Select </label>
                        <multiselect v-model="time" :options="timeFilterOptions" :show-labels="false" />
                    </div>
                    <!-- Payment Type END-->

                    <!-- Apply filter -->
                    <div class="col-md-2" style="width: auto;">
                        <button class="btn btn-outline-primary mt-4" v-on:click="applyFilter()">
                            Apply Filter
                        </button>
                    </div>
                    <!-- Apply filter END-->

                    <!-- Clear filter -->
                    <div class="col-md-2" style="width: auto;">
                        <button class="btn btn-outline-danger mt-4" v-on:click="clearFilter()">
                            Clear Filter
                        </button>
                    </div>
                    <!-- Clear filter END-->
                </div>
            </div>
        </div>

        <!--FILTER END------>

        <!--CARDS START  -->
        <div class="row" v-if="this.firstActive == 'active'" style="margin: 10px 0 0">
            <div class="col-md-6 col-xl-3 overviewCard">
                <div class="card">
                    <div class="card-body">
                        <div class="float-end mt-2">
                        </div>
                        <div>
                            <h4 class="cardTitle mb-1 mt-1">
                                {{ this.totalTicket }}
                            </h4>
                            <p class="text-primary card-title mb-0">Total Tickets</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-xl-3 overviewCard">
                <div class="card">
                    <div class="card-body">
                        <div class="float-end mt-2">
                        </div>
                        <div>
                            <h4 class="cardTitle mb-1 mt-1">
                                {{ this.totalOpen }}
                            </h4>
                            <p class="text-primary card-title mb-0">Total Open</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-xl-3 overviewCard">
                <div class="card">
                    <div class="card-body">
                        <div class="float-end mt-2">
                        </div>
                        <div>
                            <h4 class="cardTitle mb-1 mt-1">
                                {{ this.totalClosed }}
                            </h4>
                            <p class="text-primary card-title mb-0">Total Closed</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--CARDS END-->

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row mt-4">
                            <div class="col-sm-12 col-md-4" style="margin-bottom:15px;">
                                <div role="group" class="btn-group">
                                    <button v-if="this.$storageData.empTypeID == 1" type="button"
                                        class="btn btn-outline-primary" @click="downloadSample()">
                                        Download Report
                                        <b-spinner v-if="excelDownloading" small type="grow"></b-spinner>
                                        <b v-if="excelDownloading">Loading...</b>
                                    </button>
                                </div>  
                            </div>
                            <div class="col-sm-12 col-md-4">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show &nbsp;&nbsp;
                                        <b-form-select style="margin-left:5px; margin-right:5px" v-model="perPage"
                                            size="sm" :options="pageOptions"></b-form-select>&nbsp;&nbsp;&nbsp;
                                        entries
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-4 row">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input v-model="filter" type="search" placeholder="Search..."
                                            class="form-control form-control-sm ms-2"></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>

                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table striped hover outlined bordered :items="tableData" :fields="fields"
                                thead-class="bg-transparent" responsive="sm" :per-page="perPage"
                                :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                                :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered"
                                :headers="headers" fixed-header :busy="isReportTableBusy">
                                <template #table-busy>
                                    <div class="text-center text-danger my-2">
                                        <b-spinner variant="primary" class="align-middle" type="grow"></b-spinner>
                                        <strong class="text-primary">Loading...</strong>
                                    </div>
                                </template>
                                <template v-slot:cell(status)="{ value }">
                                    <span :class="`${getColor(status)}`">
                                        {{ value }}
                                    </span>
                                </template>
                                <template v-if="this.actionOpen===true" v-slot:cell(Action)="data">
                                    <button type="button" class="btn btn-soft-info btn-info" title="Reply" @click="giveReply(data.item.TicketId)">Close</button>
                                </template>
                            </b-table>

                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-end">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage">
                                        </b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="modal-lg-reply" size="lg" centered ref="modalClose">
            <div class="container deliveryAddContainer">
                <h4>Add Reply</h4>
                <!-- <input class="form-control" type="text"  v-model="replyField" placeholder="Type Your Reply"> -->
                <textarea type="text" class="form-control" v-model="replyField" rows="4" placeholder="Type Your Reply"
                    cols="50" :disabled="isEdit" track-by="replyField" :class="{
                        'is-invalid': submitted && $v.replyField.$error,
                    }"></textarea>
                <div v-if="submitted && $v.replyField.$error" class="invalid-feedback">
                    <span v-if="!$v.replyField.required">This value is required.</span>
                </div>
            </div>
            <template #modal-footer>
                <div class="w-100">
                    <b-spinner v-if="loadingtable" class="m-2 col-3" variant="primary" role="status"></b-spinner>
                    <b-button class="float-right btn btn-soft-info btn-info" @click="saveReply">
                        <span> Submit </span>
                    </b-button>
                    <!-- / footer Btn end -->
                </div>
            </template>
        </b-modal>
    </Layout>
</template>
<style scoped>
.table> :not(caption)>*>* {
    padding: 5px 8px !important;
}
</style>

